/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect } from 'react'
import {useDispatch} from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {register} from '../redux/AuthCRUD'
import {Link} from 'react-router-dom'
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";



const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  

  


  

  useEffect(()=>{
    PasswordMeterComponent.bootstrap();
  }, []);

  return (

    <Formik
       initialValues = {{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        acceptTerms: false,
      }}
      validationSchema = {registrationSchema}
      onSubmit = {(values, {setStatus, setSubmitting}) => {
        setLoading(true)
        setTimeout(() => {
          register(values.email, values.firstName, values.lastName, values.password)
            .then(({data: {token}}) => {
              setLoading(false)
              dispatch(auth.actions.register(token))
            })
            .catch(() => {
              setLoading(false)
              setSubmitting(false)
              setStatus('Registration process has broken')
            })
        }, 1000)
      }}
     >
       {({ errors, touched, handleSubmit, status, isSubmitting, isValid, values }) => (
         <Form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={handleSubmit}>
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Create an Account</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Already have an account?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Forgot Password ?
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}



      {status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>First name</label>
          <Field
            placeholder='First name'
            type='text'
            autoComplete='off'
            name="firstName"
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': touched.firstName && errors.firstName,
              },
              {
                'is-valid': touched.firstName && !errors.firstName,
              }
            )}
      
          />
          {touched.firstName && errors.firstName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors.firstName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
            <Field
              placeholder='Last name'
              type='text'
              autoComplete='off'
              name='lastName'
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': touched.lastName && errors.lastName,
                },
                {
                  'is-valid': touched.lastName && !errors.lastName,
                }
              )}
            />
            {touched.lastName && errors.lastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.lastName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <Field
          placeholder='Email'
          type='email'
          autoComplete='off'
          name='email'
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': touched.email && errors.email},
            {
              'is-valid': touched.email && !errors.email,
            }
          )}
        />
        {touched.email && errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <Field
              type='password'
              placeholder='Password'
              autoComplete='off'
              name='password'
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': touched.password && errors.password,
                },
                {
                  'is-valid': touched.password && !errors.password,
                }
              )}
            />
            {touched.password && errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
              className="d-flex align-items-center mb-3"
              data-kt-password-meter-control="highlight"
          >
            <div
                className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
            ></div>
            <div
                className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
            ></div>
            <div
                className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
            ></div>
            <div
                className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
            ></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className="text-muted">
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <Field
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          name='confirmPassword'
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': touched.confirmPassword && errors.confirmPassword,
            },
            {
              'is-valid': touched.confirmPassword && !errors.confirmPassword,
            }
          )}
        />
        {touched.confirmPassword && errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <Field
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            name='acceptTerms'
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree to the{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link>
            .
          </label>
          {touched.acceptTerms && errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={isSubmitting || !isValid || !values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
      </Form>
       )}
     </Formik>
  )
}
